import React from "react";
import { BrowserRouter as Router, NavLink, Route } from "react-router-dom";
import Home from "./app/pages/home";
import ContactUs from "./app/pages/contact-us";
import WhatWeDo from "./app/pages/what-we-do";

class App extends React.Component {
  render() {
    return (
      <Router>
        <div className="app">
          <div className="fuzz"></div>
          <div className="logo">
            <h1>root@localhost:~#</h1>
          </div>
          <div className="menu">
            <NavLink exact to="/" activeClassName="active">Home</NavLink>
            <NavLink exact to="/what-we-do" activeClassName="active">What we do</NavLink>
            <NavLink exact to="/contact-us" activeClassName="active">Contact us</NavLink>
          </div>
          <main>
            <Route exact path="/" component={Home} />
            <Route exact path="/what-we-do" component={WhatWeDo} />
            <Route exact path="/contact-us" component={ContactUs} />
          </main>
          <footer>
            root@localhost ltd, est. 2018
          </footer>
        </div>
      </Router>
    );
  }
};

export default App;
