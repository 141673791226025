import React from "react";
import Recaptcha from 'react-google-invisible-recaptcha';

class ContactUs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      message: ""
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }


  render() {
    return (
      <div className="page-contact-us">
        <p className="text-center">
          Get in touch by email
          {" "}
          <a href="#" className="ier89297425235"
             data-n="hi"
             data-d="rootatlocalhost"
             data-t="uk"
             onClick={e => {
               const email = e.currentTarget.dataset.n +
                 "@" + e.currentTarget.dataset.d + "." + e.currentTarget.dataset.t;
               location.href = "mai" + "lto:" + email;
             }} />
          .{" "}
          {/*or use form below.*/}
        </p>

        {/*<div>*/}
          {/*Name:<br />*/}
          {/*<input name="name" value={this.state.name} onChange={this.onChange} />*/}

          {/*<br /><br />*/}

          {/*Email:<br />*/}
          {/*<input name="email" value={this.state.email} onChange={this.onChange} />*/}
          {/*<br /><br />*/}

          {/*Message:<br />*/}
          {/*<textarea name="message" value={this.state.message} onChange={this.onChange} />*/}
          {/*<br /><br />*/}

          {/*<Recaptcha*/}
            {/*ref={ ref => this.recaptcha = ref }*/}
            {/*sitekey="6LeovH8UAAAAAEpfYLrGvYumOwHDBpeXL4WAWHn4"*/}
            {/*onResolved={ this.onResolved } />*/}

          {/*<button type="submit">Submit</button>*/}
        {/*</div>*/}
      </div>
    );
  };
}

export default ContactUs;
