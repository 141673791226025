import React from "react";

class Home extends React.Component {
  render() {
    return (
      <div>
        <p className="text-center">Hello world!</p>
      </div>
    );
  };
}

export default Home;
