import React from "react";

class WhatWeDo extends React.Component {
  render() {
    return (
      <div>
        <h2>Web development.</h2>

        <p>We deliver websites and mobile apps with secure RESTful APIs.</p>

        <p>Technologies we use:</p>
        <ul className="square">
          <li>React</li>
          <li>Angular</li>
          <li>PHP - Laravel</li>
          <li>Python - Flask</li>
          <li>Docker</li>
        </ul>

        <p>We have experience working for:</p>
        <ul className="square">
          <li>government sector</li>
          <li>e-commerce</li>
          <li>digital agencies</li>
        </ul>
      </div>
    );
  };
}

export default WhatWeDo;
